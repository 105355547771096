<script>
import { eventBus } from "@/main";
export default {
  name: "ReclutamientoView",
  methods: {
    volverALista() {
      eventBus.$emit("volverALista");
    },
  },
};
</script>
<template>
  <section>
    <header
      class="header bg-primary px-3 py-3 d-flex flex-column flex-lg-row justify-content-between align-items-center"
    >
      <router-link :to="{ name: 'ListaDeVacantes' }">
        <img src="/img/HXLogoWhite.png" alt="Logo" width="200" />
      </router-link>
      <nav class="d-flex justify-content-center mt-3 mt-lg-0">
        <router-link :to="{ name: 'ListaDeVacantes' }">
          <vs-button
            size="large"
            primary
            :danger="
              $route.name === 'ListaDeVacantes' ||
              $route.name === 'InformacionVacante'
            "
            @click="volverALista"
            ><i class="fa-solid fa-list mr-2"></i> Lista de vacantes</vs-button
          >
        </router-link>
        <router-link :to="{ name: 'NavegacionAspirante' }">
          <vs-button
            size="large"
            primary
            :danger="$route.name === 'NavegacionAspirante'"
          >
            <i class="fa-solid fa-magnifying-glass mr-2"></i>
            Seguimiento de postulación
          </vs-button>
        </router-link>
      </nav>
    </header>

    <router-view> </router-view>
  </section>
</template>
<style scoped>
nav .router-link-active {
  color: #ffffff;
}
.nav-item {
  font-size: 1.2rem;
  font-weight: 500;
}

.nav-item::before {
  padding: 2rem;
  color: #f5f5f5;
}
.nav-item:first-child::before {
  content: "";
}
</style>
